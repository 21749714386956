import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError, ReplaySubject } from 'rxjs';
import { catchError, takeUntil, debounceTime, map, finalize } from 'rxjs/operators';
import { ServiceMessagesService } from '../shared/service-messages.service';
import { Router } from '@angular/router';
import { FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
// import { IntercomService } from '../shared/intercom.service';
// import { AccountService } from '../account/account.service';
// import { PaymentsService } from '../account/main/payments/payments.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public completing_credentials: any = null;
  
  constructor(
    private http: HttpClient, 
    private service_messages: ServiceMessagesService,
    private router: Router, 
    // private intercom_service: IntercomService,
    // private account_service: AccountService,
    // private payments_service: PaymentsService,
  ) { }

  public set_confirm_mail(activation_code: any): void {
    this.http.post<any>(`${environment.API_URL}api/login/change-email-confirm/`, {activation_code: activation_code})
    .pipe(
      takeUntil(this.destroyed$),
      catchError(err => {
        if (err.status === 401) this.service_messages.show({
          text: this.service_messages.messages.email_updated_successfully
        });
        else this.service_messages.show({text: this.service_messages.messages.invalid_email_change_code});
        
        return throwError(err);
      }),
      finalize(() => {}),
    ).subscribe((value) => {});
  }

  get_credentials(data): Observable<any> {
    return this.http.post(`${environment.API_URL}api/login/`, data);
  }

  auth_social(network: string, not_guest: boolean): void {
    let url: string = null;

    switch (network) {
      case 'google': {
        url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${environment.SOCIAL_CONF.GOOGLE_CLIENT_ID}`+
        `&redirect_uri=${environment.SOCIAL_CONF.REDIRECT_URI}?_state=${network},${not_guest}`+
        `&scope=email profile&access_type=offline&response_type=code&prompt=consent`;
        break;
      }
    }

    window.location.href = url;
  }

  open_session(data: any): void {
    localStorage.setItem('yoola_app:token', data.yoola_token);
    localStorage.setItem('yoola_app:version', data.app_version);
    localStorage.setItem('yoola_app:lang', data.lang);
    localStorage.removeItem('yoola_app:referrer_id');
    this.router.navigate(['']);

    if (data.old_app_only) {
      window.location.href = `https://${environment.migration_domain}/auth?token=${data.yoola_token}&version=${data.app_version}`;
    }
    return;
  }

  close_session(): void {
    for (let k of ['yoola_app:token', 'yoola_app:version', 'yoola_app:scopes']) localStorage.removeItem(k);
    window.location.href = '/auth';
    // this.router.navigate(['/auth']);
    // this.intercom_service.user_itercom.next(null);
    // this.account_service.user_info.next(null);
    // this.account_service.balance_activity.next(null);
    // this.account_service.notifications.next(null);
    // this.account_service.resources.next(null);
    // this.account_service.resources_music.next(null);
    // this.payments_service.payment_details.next(null);
    // this.payments_service.balance.next(null);
    // this.payments_service.transactions.next(null);
    return;
  }

  passwords_match_validator(): ValidatorFn {
    return (group: FormGroup): {[key: string]: any} => {
      const password: AbstractControl = group.controls['password'];
      const confirm: AbstractControl = group.controls['confirm'];
      const err = (password && confirm && password.value !== confirm.value) ? {passwordsMatch: true} : null;
      if (err) confirm.setErrors(err);
      return err;
    }
  }

  check_token(): Observable<{result: {partner_common: string[]}}> {
    return this.http.get<{result: {partner_common: string[]}}>(`${environment.API_URL}api/service/front-permissions/`, {});
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
