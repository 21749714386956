let base_url: string = `${window.location.protocol}//${window.location.host}/`;

export const environment = {
  production: false,
  mobile_mod: (window.location.hostname.split('.')[0] === 'm') ? true : false,
  // INTERCOM_APP_ID: 'vbghvobw',
  INTERCOM_APP_ID: 'ctn85qbm',
  API_URL: 'https://api.partner.dev.yoola.com/',
  WS_URL: 'https://finance.dev.yoola.com',
  SOCIAL_CONF: {
    REDIRECT_URI: `${base_url}auth`,
    GOOGLE_CLIENT_ID: '935872604764-tjq87tjq322rgc4iie2b9i0jlakr4k9o.apps.googleusercontent.com',
  },
  resource_link: `https://join.dev.yoola.com/`,
  join_yoola: 'https://join.dev.yoola.com/',
  tg_bot_name: 'YoolaSupportDevBot',
  migration_domain: 'partner.dev.yoola.com'
};

