<div *ngIf="(component_loading | async)" class="d-flex justify-content-center" style="height: calc(100% - 70px)">
    <div style="width: 400px; height: 250px;">
        <divOpportunity exploration class="d-flex align-items-center justify-content-center h-100" [@opacityAnimation]>
            <mat-spinner strokeWidth="4" [diameter]="50"></mat-spinner>
        </divOpportunity>
    </div>
</div>

<div *ngIf="!(component_loading | async)" [@opacityAnimation]>
    <div class="container w-auto">
        <div class="row">
            <div class="mb-4 d-flex justify-content-between align-items-center">
                <h2 class="mb-0" mat-dialog-title style="font-weight: 800; font-size: clamp(18px, 2vh, 20px);">{{lang?.connect_new_channel}}</h2>
                <div class="d-flex align-items-start justify-content-center">
                    <button class="button-wrapper hover-opacity"
                        style="cursor: pointer; display: flex; align-items: center;" [mat-dialog-close]="false">
                        <mat-icon class="text-muted hover-opacity-el" style="opacity: .65;">close</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    
        <div *ngIf="view == 'main'">
            <div class="row mb-2">
                <div class="col-12 col-md-6">
                    <mat-card class="mat-elevation-z0 border gradient-bg p-0 p-md-0 h-100" style="cursor: pointer;"
                        (click)="view = 'add_channel_link'">
                        <div
                            class="d-flex justify-content-left align-items-center d-md-flex align-items-md-center justify-content-md-center mx-4 my-4 mx-md-3 my-md-4"> 
                            <div class="d-flex align-items-center d-md-flex flex-md-column align-md-items-center">
                                <div class="h-100 d-flex align-items-center">
                                    <img src="../../../../../../assets/img/youtube-icon.svg">
                                </div>
                                <div class="text-left text-md-center mt-md-3">
                                    <small class="ms-2" style="font-weight: 800; display: block;">{{lang?.add_youtube_channel}}</small>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="col-12 col-md-6 mt-4 mt-md-0">
                    <mat-card class="mat-elevation-z0 opacity-50 border gradient-bg p-0 p-md-0 h-100">
                        <div
                            class="d-flex justify-content-left align-items-center d-md-flex align-items-md-center justify-content-md-center mx-4 my-4 mx-md-3 my-md-4">
                            <div class="d-flex flex-row align-items-center d-md-flex flex-md-column align-md-items-center">
                                <div class="h-100 d-flex align-items-center">
                                    <img src="../../../../../../assets/img/i_yoola_music.svg">
                                </div>
                                <div class="text-left text-md-center mt-md-3">
                                    <small class="ms-2" style="font-weight: 800;">{{lang?.add_yoola_music}}</small>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
    
        <div *ngIf="view == 'add_channel_link'">
            <div class="row">
                <div class="col-12">
                    <small class="text-muted">{{lang?.link_to_your_youtube_channel}}</small>
                    <mat-form-field appearance="outline" class="w-100 fixed-search" floatLabel="never">
                        <input matInput placeholder="{{lang?.youtube_link_holder}}" [formControl]="link_control">
                        <img matPrefix src="../../../../../../assets/img/youtube-icon.svg" class="me-2 text-muted">
                    </mat-form-field>
                </div>
                <div class="col-12 mt-1">
                    <button mat-raised-button color="primary" class="w-100 mat-button-lg mat-elevation-z0"
                        [disabled]="link_control.invalid" (click)="send_youtube_link()">{{lang?.connect_channel}}</button>
                </div>
            </div>
        </div>
    </div>
</div>