<div class="player-container px-2 py-2" #player [@opacityAnimation] [ngClass]="{'d-none': !show}">
  <div class="header text-muted border-bottom pb-2 mb-2 d-flex justify-content-between align-items-center">
    <div class="drag-handle" style="cursor: move;">
      <mat-icon>drag_indicator</mat-icon>
    </div>
    <div class="mx-3 overflow-hidden text-muted">
      <small class="mw-100 text-truncate">{{(playlist) ? playlist.title : 'Yoola Music'}}</small>
    </div>
    <div>
      <button class="d-block button-wrapper hover-opacity" (click)="close()" style="cursor: pointer;">
        <mat-icon class="text-muted hover-opacity-el" style="opacity: .65;">close</mat-icon>
      </button>
    </div>
  </div>
  
  <div class="controls border-bottom px-2">
    <div class="row g-0 w-100">
      <div class="col-3 d-flex justify-content-start align-items-center">
        <div class="equalizer-wrapper">
          <div *ngIf="is_play" class="equalizer">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </div>
        </div>
      </div>

      <div class="col-6 d-flex justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center">
          <button class="d-block button-wrapper" style="cursor: pointer;" (click)="prev()"
          [disabled]="(current && playlist) ? (current.number == playlist.tracks[0].number) : true">
            <mat-icon>skip_previous</mat-icon>
          </button>
          
          <button class="d-block button-wrapper play_pause" style="cursor: pointer;" (click)="(current && is_play) ? pause() : play()">
            <mat-icon *ngIf="current && is_play">pause</mat-icon>
            <mat-icon *ngIf="!current || (current && !is_play)">play_arrow</mat-icon>
          </button>
        
          <button class="d-block button-wrapper" style="cursor: pointer;" (click)="next()"
          [disabled]="(current && playlist) ? (current.number == playlist.tracks[playlist.tracks.length - 1].number) : true">
            <mat-icon>skip_next</mat-icon>
          </button>
        </div>
      </div>

      <div class="col-3 d-flex justify-content-center align-items-center">
        <mat-slider
        class="volume"
        [max]="1"
        [min]="0"
        [step]="0.1"
        [formControl]="volume">
        </mat-slider>
      </div>
    </div>

    <div class="row g-0 w-100 mb-2">
      <div class="col-12">
        <mat-slider
        class="track w-100"
        [max]="100"
        [min]="0"
        [step]="1"
        [formControl]="track">
        </mat-slider>

        <div class="d-flex justify-content-between align-items-center text-muted" style="font-size: .85rem;">
          <small class="d-block">{{formatTime(currentTime)}}</small>
          <small class="d-block">{{formatTime(duration)}}</small>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="playlist" class="playlist mt-2" [ngClass]="{'scroll-list': playlist.tracks.length > 10}">
    <div *ngFor="let item of playlist.tracks" [ngClass]="{'active': current && item.number == current.number}"
    class="item text-muted px-2 d-flex justify-content-between align-items-center pt-1" 
    (click)="player_service.current.next(item.number)">
      <small class="d-block text-truncate">{{item.number}}. {{item.title}}</small>
      <!-- <div>
        123
      </div> -->
    </div>
  </div>
</div>
