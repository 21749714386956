import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';

@Pipe({ name: 'timeAgo', pure: false })
export class TimeAgoPipe extends AsyncPipe implements PipeTransform {
  public messages = null;

  private value: Date;
  private timer: Observable<string>;
  private list: string[] = ['en', 'ru', 'uk'];
  
  private lang = {
    en: {
      yesterday: 'yesterday',
      days_ago: (days: number) => `${days} days ago`,
      more_two_weeks: 'more than 2 weeks ago',
      more_month: 'more than a month ago',
    },
    ru: {
      yesterday: 'вчера',
      days_ago: (days: number) => `${days} ${days < 5 ? 'дня' : 'дней'} назад`,
      more_two_weeks: 'более 2 недель назад',
      more_month: 'более месяца назад',
    },
    uk: {
      yesterday: 'вчора',
      days_ago: (days: number) => `${days} ${days < 5 ? 'дні' : 'днів'} тому`,
      more_two_weeks: 'більше 2 тижнів тому',
      more_month: 'більше місяця тому',
    },
  };

  constructor(ref: ChangeDetectorRef) {
    super(ref);
    const ls = localStorage.getItem('yoola_app:lang');
    const nav = navigator['language'].split('-')[0];
    const lang = (ls && this.list.includes(ls)) ? ls : (nav && this.list.includes(nav)) ? nav : 'en';
    this.messages = this.lang[lang];
  }

  transform(value: any): any {
    const date = new Date(value);
    if (!isNaN(date.getTime())) {
      this.value = date;
      if (!this.timer) {
        this.timer = this.getObservable();
      }
      return super.transform(this.timer);
    }
    return super.transform(value);
  }

  private getObservable(): Observable<string> {
    return interval(1000).pipe(
      map(() => {
        const now = new Date();
        const delta_days = Math.floor((now.getTime() - this.value.getTime()) / (1000 * 60 * 60 * 24));
        let result: string;

        if (delta_days > 0) {
          if (delta_days === 1) {
              result = this.messages.yesterday;
          } else if (delta_days <= 14) {
              result = this.messages.days_ago(delta_days);
          } else if (delta_days <= 30) {
              result = this.messages.more_two_weeks;
          } else {
              result = this.messages.more_month;
          }
      }
      return result;
      })
    );
  }
}

// import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
// import { Observable, timer, map, interval } from 'rxjs';
// import { AsyncPipe } from '@angular/common';

// @Pipe({name: 'timeAgo', pure: false})
// export class TimeAgoPipe extends AsyncPipe {
//   value: Date;
//   timer: Observable<string>;

//   constructor(ref: ChangeDetectorRef) {
//     super(ref);
//   }

//   transform(obj:any):any {
//     obj = new Date(obj);
//     if (obj instanceof Date) {
//       this.value = obj;
//       if(!this.timer) this.timer = this.getObservable();
//       return super.transform(this.timer);
//     }
//     return super.transform(obj);
//   }

//   dateDiffInDays(a, b) {
//     const _MS_PER_DAY = 1000 * 60 * 60 * 24;

//     const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
//     const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    
//     return Math.floor((utc2 - utc1) / _MS_PER_DAY);
//   }

//   private getObservable():Observable<string> {
//     return interval(1000).pipe(
//       map(() => {
//         let result: string;

//         let now_local = new Date();
//         let now =  new Date(now_local.getUTCFullYear(), now_local.getUTCMonth(), now_local.getUTCDate(),
//         now_local.getUTCHours(), now_local.getUTCMinutes(), now_local.getUTCSeconds());

//         let delta_days = Math.ceil((new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() - 
//         new Date(this.value.getFullYear(), this.value.getMonth(), this.value.getDate()).getTime()) / (1000 * 60 * 60 * 24));

//         console.log(delta_days);

//         if (delta_days > 0) {
//           switch (true) {
//             case (delta_days == 1): { result = 'yesterday'; break; }
//             case (delta_days > 1 && delta_days <= 3): { result = 'few days ago'; break; }
//             default: { result = this.value.toString(); }
//           }
//         } else {
//           let delta_seconds = Math.abs(now.getTime() - this.value.getTime()) / 1000;
//           switch (true) {
//             case (delta_seconds <= 60): { result = 'just now'; break; }
//             case (delta_seconds > 60 && delta_seconds <= 1800): { result = 'few minutes ago'; break; }
//             case (delta_seconds > 1800 && delta_seconds <= 3600): { result = 'half hour ago'; break; }
//             case (delta_seconds > 3600): { result = 'today'; break; }
//           }
//         }

//         // return (result) ? result : this.value.toString();
//         return (result) ? result : '123';
//       })
//     );
//   };
// }