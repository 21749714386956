import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

Injectable()
export class RequestInterceptor implements HttpInterceptor{

  constructor (
    public auth_service: AuthService, 
    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    let Request = req.clone();

    if (localStorage && localStorage.length > 0) {
      const token = localStorage.getItem('yoola_app:token');
      const version = localStorage.getItem('yoola_app:version');

      let headers: HttpHeaders = new HttpHeaders();
      headers = req.headers.append('Authorization', `Bearer ${token}`).append('X-App-Version', `${version}`);
      Request = req.clone({headers: headers});
    }

    return next.handle(Request).pipe(
      catchError(error => {
        switch (error.status) {
          case 401: { this.auth_service.close_session(); break; }
          case 403: {
            if (error.error.reason) {
              switch (error.error.reason) {
                case 'incompatible_app_version': { this.auth_service.close_session(); break; }
                default: this.router.navigate(['']);
              }
            } else this.router.navigate(['']);
            break;
          }
          case 400: case 404: case 409: {break;}
          // default: this.auth_service.close_session();
        }
        return throwError(error);
      })
    );
  }
}