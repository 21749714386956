import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, ReplaySubject, takeUntil, filter } from 'rxjs';
import { IDataPlaylist } from './interfaces/data.interface';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerService implements OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public playlist: BehaviorSubject<IDataPlaylist> = new BehaviorSubject(null);
  public current: BehaviorSubject<number> = new BehaviorSubject(null);
  public id: string = this.shared_service.unic_id();

  constructor(
    private shared_service: SharedService
  ) {
    this.current
    .pipe(
      takeUntil(this.destroyed$),
      filter(v => v !== null)
    )
    .subscribe(v => {
      localStorage.setItem('yoola_app:player', this.id);
    });

    window.addEventListener('storage', this.storage_listener);
  }

  private storage_listener = (event: StorageEvent) => {
    if (event.key === 'yoola_app:player') {
      if (event.newValue !== this.id) {
        this.current.next(null);
        this.playlist.next(null);
      }
    }
  };

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    window.removeEventListener('storage', this.storage_listener);
  }
}
