import { NgModule } from '@angular/core';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {OverlayModule} from '@angular/cdk/overlay';

@NgModule({
  imports: [ 
    MatToolbarModule, 
    MatButtonModule, 
    MatButtonToggleModule,
    MatInputModule, 
    MatSelectModule, 
    MatRadioModule, 
    MatCheckboxModule,
    MatIconModule, 
    MatMenuModule, 
    MatCardModule, 
    MatProgressSpinnerModule, 
    MatSidenavModule, 
    MatTableModule, 
    MatPaginatorModule,
    MatAutocompleteModule, 
    MatSlideToggleModule, 
    MatDatepickerModule, 
    MatNativeDateModule, 
    MatTooltipModule, 
    MatChipsModule,
    MatDialogModule, 
    MatSortModule, 
    MatTabsModule, 
    MatSnackBarModule, 
    MatSliderModule, 
    MatGridListModule, 
    MatRippleModule, 
    MatExpansionModule,
    MatStepperModule
  ],
  exports: [ 
    MatToolbarModule, 
    MatButtonModule, 
    MatButtonToggleModule,
    MatInputModule, 
    MatSelectModule, 
    MatRadioModule, 
    MatCheckboxModule, 
    MatIconModule,
    MatMenuModule, 
    MatCardModule, 
    MatProgressSpinnerModule, 
    MatSidenavModule, 
    MatTableModule, 
    MatPaginatorModule, 
    MatAutocompleteModule,
    MatSlideToggleModule, 
    MatDatepickerModule, 
    MatNativeDateModule, 
    MatTooltipModule, 
    MatChipsModule, 
    MatDialogModule, 
    MatSortModule,
    MatTabsModule, 
    MatSnackBarModule, 
    MatSliderModule, 
    MatGridListModule, 
    MatRippleModule, 
    MatExpansionModule,
    MatStepperModule
  ],
  providers: [
  ]
})
export class MaterialModule {
  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-symbols-rounded');
  }
}
