import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Renderer2, ViewEncapsulation, OnDestroy } from '@angular/core';
import { opacityAnimation } from '../../animations/opacity.animation';
import { BehaviorSubject, combineLatest, ReplaySubject, takeUntil } from 'rxjs';
import { PlayerService } from '../../player.service';
import { Howl, Howler } from 'howler';
import { IDataPlaylist } from '../../interfaces/data.interface';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [opacityAnimation]
})
export class PlayerComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @ViewChild('player') playerContainer!: ElementRef;
  public show: boolean = false;

  sound!: Howl;
  is_play = false;
  duration: number = 0;
  timer: any = null;
  currentTime: number = 0;
  public volume: FormControl = new FormControl({value: 0.5, disabled: false});
  public track: FormControl = new FormControl({value: 0, disabled: false});

  playlist: IDataPlaylist = null;
  current: any = null;

  constructor(
    private renderer2: Renderer2,
    private player_service: PlayerService,
  ) { }

  ngOnInit(): void {
    this.player_service.playlist
    .pipe(takeUntil(this.destroyed$))
    .subscribe(v => {
      this.playlist = v;
      this.show = (v) ? true : false;
    });

    this.player_service.current
    .pipe(takeUntil(this.destroyed$))
    .subscribe(v => {
      if (v) {
        this.current = this.playlist.tracks.filter(i => i.number == v)[0];
        this.load();
      } else {
        if (this.sound) this.sound.unload();
        this.is_play = false;
      }
    });

    this.volume.valueChanges
    .pipe(takeUntil(this.destroyed$))
    .subscribe(v => {
      this.sound.volume(v);
    });

    this.track.valueChanges
    .pipe(takeUntil(this.destroyed$))
    .subscribe(v => {
      if (this.sound && this.duration) {
        const seekSeconds = (v / 100) * this.duration;
        this.sound.seek(seekSeconds);
        this.currentTime = seekSeconds;
      }
    });
  }

  ngAfterViewInit(): void {
    const container = this.playerContainer.nativeElement;
    const handle = container.querySelector('.drag-handle');
    let [x, y, drag] = [0, 0, false];

    const mousedown = (event: MouseEvent) => {
      drag = true;
      const rect = container.getBoundingClientRect();
      [x, y] = [event.clientX - rect.left, event.clientY - rect.top];
      event.preventDefault();
    };

    const mousemove = (event: MouseEvent) => {
      if (drag) {
        [container.style.left, container.style.top] = 
        [`${event.clientX - x}px`, `${event.clientY - y}px`];
      }
    };

    const mouseup = () => {
      drag = false;
    };

    this.renderer2.listen(handle, 'mousedown', mousedown);
    this.renderer2.listen('document', 'mousemove', mousemove);
    this.renderer2.listen('document', 'mouseup', mouseup);
  }

  load(): void {
    if (this.sound) {
      this.sound.unload();
      this.clearTimer();
    }

    this.sound = new Howl({
      src: [this.current.src],
      html5: true,
      volume: this.volume.value,
      onload: () => {
        this.duration = this.sound.duration();
        this.play();
      },
      onend: () => {
        this.is_play = false;
        this.next();
      }
    });
  }

  startTimer(): void {
    this.timer = setInterval(() => {
      if (this.sound.playing()) {
        this.currentTime = this.sound.seek() as number;
        const progressPercent = (this.currentTime / this.duration) * 100;
        this.track.setValue(progressPercent, { emitEvent: false });
      }
    }, 500);
  }

  clearTimer(): void {
    clearInterval(this.timer);
  }

  play(): void {
    this.sound.play();
    this.is_play = true;
    this.startTimer();
  }

  pause(): void {
    this.sound.pause();
    this.is_play = false;
    this.clearTimer();
  }

  next(){
    const index = this.playlist.tracks.findIndex(i => i.number === this.current.number);
    if (this.playlist.tracks[index + 1]) {
      this.player_service.current.next(this.playlist.tracks[index + 1].number);
    }
  }

  prev(){
    const index = this.playlist.tracks.findIndex(i => i.number === this.current.number);
    if (this.playlist.tracks[index - 1]) {
      this.player_service.current.next(this.playlist.tracks[index - 1].number);
    }
  }

  close(): void {
    this.show = false;
    if (this.sound) {
      this.sound.unload();
      this.player_service.playlist.next(null);
      this.player_service.current.next(null);
    }
  }

  formatTime(seconds: number): string {
    const m = Math.floor(seconds / 60).toString().padStart(2, '0');
    const s = Math.floor(seconds % 60).toString().padStart(2, '0');
    return `${m}:${s}`;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
