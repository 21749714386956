import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, combineLatest, throwError, ReplaySubject, merge, Subject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IUserInfo, IUserNotification, IUserIntercom } from '../shared/interfaces/user.interface';
import { IBalanceActivity } from '../shared/interfaces/payment.interface';
import { ServiceMessagesService } from '../shared/service-messages.service';
import { catchError, takeUntil, debounceTime, finalize, map } from 'rxjs/operators';
import { CurrencyPipe } from '@angular/common';
import { IDataResources } from '../shared/interfaces/data.interface';
import { IntercomService } from '../shared/intercom.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService implements OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public user_info: BehaviorSubject<IUserInfo> = new BehaviorSubject(null);
  public balance_activity: BehaviorSubject<IBalanceActivity> = new BehaviorSubject(null);
  public notifications: Subject<IUserNotification[]> = new Subject();
  public resources: BehaviorSubject<IDataResources> = new BehaviorSubject(null);
  public resources_music: BehaviorSubject<IDataResources> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient, 
    private service_messages: ServiceMessagesService,
    private currency_pipe: CurrencyPipe,
    private intercom_service: IntercomService
  ) {
  }

  public init(): void {
    this.get_user_info();
    this.get_balance_activity();
    this.get_notiications();
    this.get_resources();
    this.get_user_intercom();
    // this.get_notiications_mock();
  }

  public get_user_info(): void {
    this.http.get<IUserInfo>(`${environment.API_URL}api/partner/info/`)
    .pipe(
      takeUntil(this.destroyed$),
      catchError(err => this.service_messages.error(err)),
      finalize(() => {})
    )
    .subscribe((data: IUserInfo) => {
      // data.lang = 'en'
      this.user_info.next(data);
    });
  }

  public get_user_intercom(): void {
    this.http.get<{intercom: IUserIntercom}>(`${environment.API_URL}api/service/iwc/`)
    .pipe(
      takeUntil(this.destroyed$),
      catchError(err => this.service_messages.error(err)),
      finalize(() => {}),
      map(data => data.intercom)
    )
    .subscribe((data: IUserIntercom) => {
      this.intercom_service.user_itercom.next(data);
    });
  }

  private get_resources(): void {
    this.http.get<{results: IDataResources}>(`${environment.API_URL}api/partner/channels/`)
    .pipe(
      takeUntil(this.destroyed$),
      catchError(err => this.service_messages.error(err)),
      finalize(() => {}),
      map(response => {
        response.results.channels_msn.map(i => {
          i.resource_type = 'yt';
        });

        response.results.yoola_music = response.results.yoola_music.map(i => {
          i.image_url = '../../../../assets/img/music_resource.png';
          i.title = i.page_title;
          i.id = 'Yoola Music';
          i.resource_type = 'music';
          return i;
        })
        .sort((a, b) => (a.is_partner) ? -1 : 1);

        response.results.resource_list = response.results.channels_msn.concat(<any>response.results.yoola_music).map(i => {
          i.type = (i.is_partner) ? 'active' : 'unlinked';
          return i;
        })
        .sort((a, b) => (a.is_partner) ? -1 : 1)
        .sort((a, b) => (a.resource_type == 'yt' && a.is_partner) ? -1 : 1);

        return response;
      }),
      map(response => response.results)
    )
    .subscribe((data: IDataResources) => {
      data.app_data = {
        yt_count: data.channels_msn.length + data.channels_pending.length + data.yoola_music.length
      };

      this.resources.next(data);
    });
  }

  private get_balance_activity(): void {
    this.http.get<IBalanceActivity>(`${environment.API_URL}finance/partner-balance/new-widget/`)
    .pipe(
      takeUntil(this.destroyed$),
      catchError(err => this.service_messages.error(err)),
      finalize(() => {})
    )
    .subscribe((data: IBalanceActivity) => {
      const usd_balance = this.currency_pipe.transform(data.balance.USD, 'USD', 'symbol', '1.2-2', 'en-US');
      data.app_data = {
        usd_balance: usd_balance,
        usd_balance_arr: usd_balance.split('.')
      };

      data.last_transactions = data.last_transactions.map(i => {
        i.app_data = {
          img: '../../../../../assets/img/payments/transaction_default.png'
        };

        switch (i.source_destination.code) {
          case 'yt_ref': case 'yt': 
          i.app_data.img = '../../../../../assets/img/payments/transaction_income.png'; break;
          case 'privat24': case 'visa_mastercard': case 'visa_mastercard_non_rub': 
          i.app_data.img = '../../../../../assets/img/payments/transaction_bank.svg'; break;
          case 'yoola_music': 
          i.app_data.img = '../../../../../assets/img/payments/transaction_music.png'; break;
          case 'paypal': 
          i.app_data.img = '../../../../../assets/img/payments/transaction_paypal.png'; break;
          case 'crypto_btc': 
          i.app_data.img = '../../../../../assets/img/payments/transaction_crypto_btc.svg'; break;
          // case 'crypto_eth': 
          // i.app_data.img = '../../../../../assets/img/payments/transaction_crypto.svg'; break;
          // case 'wire_transfer': 
          // i.app_data.img = '../../../../../assets/img/payments/transaction_bank.svg'; break;
        }

        return i;
      });
      this.balance_activity.next(data);
    });
  }

  public get_notiications(): void {
    this.http.get<{results: IUserNotification[]}>(`${environment.API_URL}api/service/notifications/v2/`)
    .pipe(
      takeUntil(this.destroyed$),
      catchError(err => this.service_messages.error(err)),
      finalize(() => {}),
      map((response) => response.results)
    )
    .subscribe((data: IUserNotification[]) => {
      this.notifications.next(data);
    });
  }

  // public get_notiications_mock(): void {
  //   this.http.get<{results: IUserNotification[]}>(`${environment.API_URL}api/service/notifications/v2/mock/?notifications=new_application_is_pending`)
  //   .pipe(
  //     takeUntil(this.destroyed$),
  //     catchError(err => this.service_messages.error(err)),
  //     finalize(() => {}),
  //     map((response) => response.results)
  //   )
  //   .subscribe((data: IUserNotification[]) => {
  //     this.notifications.next(data);
  //   });
  // }

  public set_accept_srteams(): Observable<any> {
    return this.http.post<any>(`${environment.API_URL}api/service/yoola-streams/`, {})
    .pipe(
      takeUntil(this.destroyed$),
      catchError(err => this.service_messages.error(err)),
      finalize(() => {}),
    )
  }

  public get_tos(channel_id): Observable<any> {
    const params: HttpParams = new HttpParams({}).set('cid', channel_id).set('boost', 'true');
    return this.http.get<any>(`${environment.API_URL}api/service/terms/mcn/`, {params: params})
    .pipe(
      takeUntil(this.destroyed$),
      catchError(err => this.service_messages.error(err)),
      finalize(() => {}),
    )
  }

  public set_accept_tos(data): Observable<any> {
    return this.http.post<any>(`${environment.API_URL}api/service/terms/mcn/`, data)
    .pipe(
      takeUntil(this.destroyed$),
      catchError(err => this.service_messages.error(err)),
      finalize(() => {}),
    )
  }

  public send_verification_code(data, wa): Observable<any> {
    const url = (wa) ? `${environment.API_URL}api/partner/phone-number-verification-via-whatsapp/` : 
    `${environment.API_URL}api/partner/phone-number-verification/`;
    return this.http.post<any>(url, data)
    .pipe(
      takeUntil(this.destroyed$),
      catchError(err => {
        if (err.status !== 409) return this.service_messages.error(err);
        else return throwError(err);
      }),
      finalize(() => {}),
    )
  }

  public set_verification_code(data): Observable<any> {
    return this.http.post<any>(`${environment.API_URL}api/partner/phone-number-verification/`, data)
    .pipe(
      takeUntil(this.destroyed$),
      catchError(err => {
        if (err.status !== 400) return this.service_messages.error(err);
        else return throwError(err);
      }),
      finalize(() => {}),
    )
  }

  public send_youtube_link(data): Observable<any> {
    return this.http.post<any>(`${environment.API_URL}api/partner/channels-youtube/`, data)
    .pipe(
      takeUntil(this.destroyed$),
      catchError(err => this.service_messages.error(err)),
      finalize(() => {}),
    )
  }

  public uploading_kyc_document(file, id): Observable<any> {
    let body = new FormData();
    body.append('files[]', file);
    return this.http.patch<any>(`${environment.API_URL}finance/kyc/${id}/`, body)
    .pipe(
      takeUntil(this.destroyed$),
      catchError(err => this.service_messages.error(err)),
      finalize(() => {}),
    );
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
