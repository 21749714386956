import { Component, OnInit, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IntercomService } from './shared/intercom.service';
import { LangService } from './shared/lang.service';
import { SharedService } from './shared/shared.service';
import { PlayerService } from './shared/player.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private renderer: Renderer2;

  constructor(
    private intercom_service: IntercomService,
    private lang_service: LangService,
    private shared_service: SharedService,
    private player_service: PlayerService,
    private renderer_factory: RendererFactory2
  ) {
    this.renderer = renderer_factory.createRenderer(null, null);
    this.shared_service.update_theme();
  }

  ngOnInit(): void {
    this.shared_service.theme
    .pipe(takeUntil(this.destroyed$))
    .subscribe(data => {      
      this.renderer.setAttribute(document.body, 'theme', data);
      this.renderer.setAttribute(document.documentElement, 'theme', data);
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}