import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { environment } from '../../environments/environment'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { AgmCoreModule } from '@agm/core';
import { DatePipe } from '@angular/common';
import { RequestInterceptor } from './request-interceptor.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { DialogConfirmationComponent } from './components/dialog-confirmation/dialog-confirmation.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { DecimalPipe } from '@angular/common';
import { ClipboardModule } from 'ngx-clipboard';
import { IntercomModule } from 'ng-intercom';
import { DialogAlertComponent } from './components/dialog-alert/dialog-alert.component';
import { CustomSnackbarComponent } from './components/custom-snackbar/custom-snackbar.component';
import { PlayerComponent } from './components/player/player.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import localeRu from '@angular/common/locales/ru';
import localeEn from '@angular/common/locales/en';
import localeUk from '@angular/common/locales/uk';

registerLocaleData(localeRu, 'ru');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeUk, 'uk');

const saved_locale = localStorage.getItem('yoola_app:lang');
const default_locale = saved_locale ? saved_locale : 'en';


@NgModule({
  declarations: [
    DialogConfirmationComponent,
    ShortNumberPipe,
    FileSizePipe,
    DialogAlertComponent,
    CustomSnackbarComponent,
    PlayerComponent,
    TimeAgoPipe
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule, 
    ReactiveFormsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({apiKey: "AIzaSyDVrKggVflslTSBvR-NpPLt6jndP9LXKJc", libraries: ["places"], language: 'en'}),
    IntercomModule.forRoot({appId: environment.INTERCOM_APP_ID, updateOnRouterChange: true})
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule, 
    ReactiveFormsModule,
    DialogConfirmationComponent,
    ShortNumberPipe,
    FileSizePipe,
    ClipboardModule,
    TimeAgoPipe,
    PlayerComponent
  ],
  entryComponents: [
    DialogConfirmationComponent,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true, 
    useFactory: (AuthService: AuthService, Router: Router) => 
    new RequestInterceptor(AuthService, Router), 
    deps: [AuthService]},
    DatePipe,
    CurrencyPipe,
    DecimalPipe,
    { provide: LOCALE_ID, useValue: default_locale }
  ],
})
export class SharedModule {}
